import React from 'react';
import DataTable from 'react-data-table-component';
import {useTranslate} from "@tolgee/react";

interface FacebookPagesTableProps {
    profiles: any[]; // Replace any with the actual type of a Facebook page
    columns: any[];
}

const SocialMediaProfileTable: React.FC<FacebookPagesTableProps> = ({
                                                                   profiles,
                                                                   columns
                                                               }) => {
    const {t} = useTranslate();

    return (
        <div className={'py-5'}>
            <div className={'d-flex flex-row'}>
                <div className={'h5 me-2 small text-muted'}>{t('verifyFacebookAccessToken.Connected')}: <span
                        className={""}>{profiles.length}</span></div>
            </div>

            <div>
                {profiles && (
                    <DataTable
                    columns={columns}
                    data={profiles}
                    pagination
                    paginationComponentOptions={{
                        rangeSeparatorText: t('verifyFacebookAccessToken.table.of'),
                        rowsPerPageText: t('verifyFacebookAccessToken.table.entries_per_page')
                    }}
                />
                )}

            </div>
        </div>
    );
};

export default SocialMediaProfileTable;
