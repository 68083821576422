import {
  COMMENTS,
  IMPRESSIONS,
  REACTIONS,
  SHARES,
  VIDEO_VIEWS
} from '../../config/constants';

const en = {
  insights_not_available_by_platform: 'Insights not provided by the platform',
  growth: 'Growth',
  post_reach: 'Post Reach',
  engagement: 'Engagement',
  last_published_post: 'Last Published',
  best_performance_post: 'Best Performance',
  next_to_publish_post: 'Next to Publish',
  last_edited_post: 'Last Edited',
  your_last_posts: 'Your Last Posts',
  post: 'Post',
  type: 'Type',
  published_on: 'Published On',
  date: 'Date',
  search: 'Search',
  columns: 'Columns',
  apply: 'Apply',
  cancel: 'Cancel',
  loading: 'Loading...',
  updating: 'Updating',
  content_type_is_story_post_ony: 'Stories only',
  content_type_is_text_post: 'Text only',
  content_type_is_single_image_post_ony: 'Single image only',
  content_type_is_multi_image_post_ony: 'multi-images onyl',
  content_type_is_video_post_ony: 'Videos only',
  content_type_is_reel_post_ony: 'Reels only',
  open_all_plots: 'Open all analytics',
  facebook: 'Facebook',
  twitter: 'Twitter',
  instagram: 'Instagram',
  google: 'Google',
  linkedin_personal_account: 'Linkedin Personal Account',
  linkedin_business_page: 'Linkedin Business Page',
  last_seven_days: 'Last seven days',
  this_week: 'This Week',
  last_week: 'Last Week',
  this_month: 'This Month',
  last_month: 'Last Month',
  Calendar: 'Calendar',
  Cancel: 'Cancel',
  Apply: 'Apply',
  likes: 'Likes',
  followers: 'Followers',
  page_visits: 'Page Visits',
  posts: 'Posts',
  last_edited_by: 'Last Edited by',
  created_by: 'Created by',
  last_update: 'Last Updated',
  publication_date: 'Publication Date',
  list_view: 'List View',
  analytics: 'Analytics',
  edit: 'Edit',
  view: 'View',
  delete: 'Delete',
  no_content_available: 'There is no content available.',
  created: 'Created',
  old_to_new: 'Old to new',
  new_to_old: 'New to old',
  published: 'Published',
  edited: 'Edited',
  organic: 'Organic',
  Fans: 'Fans',
  fans: 'fans',
  call_to_action: 'Call to action',
  website_button: 'Website button',
  interactions: 'Interactions',
  online_followers: 'Online followers',
  reach: 'Reach',
  clicks: 'Clicks',
  organic_summary: 'Organic summary',
  organic_interactions: 'Organic interactions',
  facebook_clicks: 'Clicks',
  followers_development: 'Followers',
  fan_development: 'Fan development',
  follower_development: 'Follower development',
  post_impressions: 'Post impressions',
  shared: 'Shared',
  overview: 'Overview',
  reviewers: 'Reviewers',
  star_rating: 'Stars rating',
  google_search: 'Google Search',
  google_maps: 'Google Maps',
  total: 'Total',
  website: 'Website',
  phone: 'Phone',
  directions: 'Directions',
  tweets: 'Tweets',
  following: 'Following',
  summary: 'Summary',
  no_data_available: 'No data available',
  clicks_on_page: 'Clicks on Page',
  posts_published_in_period: 'Posts Published in Period',
  community: 'Community',
  tweets_published_in_period: 'Tweets Published in Period',
  likes_development: 'Likes development',
  account: 'Account',
  location: 'Location',
  development_during_the_first_30_days: 'Development during the first 30 days',
  [IMPRESSIONS]: 'Impressions',
  [REACTIONS]: 'Reactions',
  [SHARES]: 'Shares',
  [COMMENTS]: 'Comments',
  [VIDEO_VIEWS]: 'Video Views',
  posts_viewed_in_period: 'Posts Viewed in Period',
  impressions_by_type: 'Impressions by Type',
  videos: 'Videos',
  paid: 'Bezahlt',
  posts_impressions_in_period: 'Posts Impressions in Period',
  retweets: 'Retweets',
  profile_views: 'Profile Views',
  post_reactions: 'Post Reactions',
  profile_followers: 'Profile Followers',
  video_impressions: 'Video Impressions',
  impressions_by_post_type: 'Impressions by Post Type',
  impressions_by_source: 'Impressions by Source',
  go_to_post: 'Go to post',
  rows_per_page: 'Posts per page',
  of: 'of',

  metric_not_available_for_platform: 'Metric not available for this platform',
  its_not_possible_to_change_date_range_on_demo_location:
    'Demo: You can not change the date range',
  platform_analytics: {
    google_info: 'Google data is only available until two days ago'
  },
  mixedChart: {
    mixedChart: {
      posts_per_day: 'Posts',
      posts_per_day_plot: 'Posts per Day'
    },
    there_is_some_missing_data:
      "Some historical data couldn't be retrieved due to limitations of the social network.",
    data_import_is_currently_in_progress:
      'Data import is currently in progress and will be soon available for the selected period.',
    analytics_data_is_currently_synchronizing:
      'Analytics data is currently synchronizing and will be soon available',
    google_info: 'Google data is only available until two days ago',
    overview: 'Overview',
    post_reactions: 'Post Reactions',
    post_impressions: 'Post Impressions',
    post_reach: 'Post Reach',
    Growth: 'Growth',
    growth: 'Growth',
    impressions: 'Impressions',
    reach: 'Reach',
    reactions: 'Reactions',
    instagram: 'Instagram',
    linkedin_business_page: 'LinkedIn Business Page',
    google: 'Google',
    facebook: 'Facebook',
    twitter: 'Twitter',
    linkedin_personal_account: 'LinkedIn Personal Account',
    posts_per_day: 'Posts',
    no_data_is_available: 'No data is available for the current period.',
    reach_and_impressions: 'Reach and impressions',
    page_visits: 'Page Visits',
    follower_details: 'Followers Details',
    fans: 'Fans',
    follower_development: 'Follower development',
    gained: 'Gained',
    lost: 'Lost',
    clicks_on_page: 'Clicks on Page',
    call_to_action: 'Call to Action',
    website_button: 'Website Button',
    total_interactions: 'Total Interactions',
    profile_followers: 'Profile Followers',
    profile_analytics: 'Profile Analytics',
    profile_links_taps: 'Profile Links Taps',
    website_clicks: 'Website Clicks',
    profile_views: 'Profile Views',
    business_profile_interactions: 'Business Profile Interactions',
    impressions_desktop: 'Impressions Desktop',
    maps: 'Maps',
    search: 'Search',
    impressions_mobile: 'Impressions Mobile',
    calls: 'Calls',
    messages: 'Messages',
    bookings: 'Bookings',
    directions: 'Directions',
    paid_followers: 'Paid Followers',
    organic_followers: 'Organic Followers',
    new_followers: 'New Followers',
    new_paid_followers: 'New Paid Followers',
    new_organic_followers: 'New Organic Followers',
    unique_page_views: 'Unique Page Views',
    followers: 'Followers',
    likes: 'Likes',
    at_least_one_metric_required: 'At least one metric is required',
    what_does_this_mean: 'What does this mean?',
    profile_engagement: 'Engagmement',
    error_while_loading_data: 'Error while loading data',
    page_views: 'Page Views',
    favourites_count: 'Favourites Count',
    followers_count: 'Followers Count',
    friends_count: 'Friends Count',
    listed_count: 'Listed Count',
    statuses_count: 'Statuses Count',
    posts_per_day_plot: 'Posts per Day',
    videos_uploaded: 'Videos Uploaded',
    videos_count: 'Videos Count',
    subscribers_gained: 'Subscribers Gained',
    subscribers: 'Subscribers',
    subscribers_lost: 'Subscribers Lost',
    interactions: 'Interactions',
    shares: 'Shares',
    comments: 'Comments',
    dislikes: 'Dislikes'
  },
  posts_per_day_plot: 'Posts per Day',
  last_30_days: 'Last 30 Days',
  last_60_days: 'Last 60 Days',
  custom_range: 'Custom Range',
  last_3_months: 'Last 3 Months',
  last_6_months: 'Last 6 Months',
  last_12_months: 'Last 12 Months',
  no_posts_have_been_published_with_lomavis:
    'No posts have been published with Lomavis',
  only_google_is_connected:
    'Other metrics such as reaction, followers, and more will be available as soon as more profiles are connected',
  only_google_is_connected_description: '',
  instagram_profile_followers_info:
    'Historical data is only available for Instagram Business Profiles with over 100 followers.',
  range_should_at_least_be_5_days_long: 'Select at least a range of five days',
  data_not_available_for_facebook_group_posts:
    'Data not available for Facebook group posts',
  short_analytics_table: {
    analytics_data_is_currently_synchronizing:
      'Analytics data is currently synchronizing and will be soon available'
  },
  twitter_profile_analytics: {
    twitter_analytics_info:
      'It is not possible to get historical data for Twitter. As long as an account is connected, analytics data is retrieved daily to provide build your analytics dashboard.'
  },
  platforms: 'Platforms',
  topPosts: {
    no_next_to_publish_post: 'No post planned',
    no_best_performance_post: 'No post available',
    no_last_published_post: 'No post available',
    no_last_edited_post: 'No post available',
    timerange_info:
      'Posts published within the last 28 days are being considered. The number of impressions is used to determine the best performing post.',
    post_is_shared_without_comment: 'Post has been shared without comment'
  },
  post_types: {
    single_image_post: 'Einzelbildpost',
    multi_image_post: 'Multi-Image Post',
    video_post: 'Video',
    reel_post: 'Reel',
    story_post: 'Story',
    text_post: 'Text Post',
    imported_post: 'Importierter Post',
    post_is_shareable: 'Geteilter Post'
  },
  analyticsCards: {
    last_28_days: 'Last 28 Days',
    reach_info_text:
      'Number of people who saw one of your posts at least once during the period. Reach differs from impressions in that multiple views by one person are not counted multiple times.',
    impressions_info_text:
      'Number of times your posts were viewed during the period. Impressions differ from reach in that an impression is counted each time the post is seen. This also applies if the same person sees your post multiple times.',
    reactions_info_text:
      'Number of reactions to your posts in the period. This includes all reactions such as likes, love, applause, etc.',
    posts_per_day_info_text:
      'Number of posts published in the period. Normal posts, video posts and reels are considered. Stories cannot be taken into account as they are no longer available after a maximum of 24 hours.',
    saved_posts_per_day_info_text: 'Number of posts saved in Lomavis'
  },

  overallCard: {
    Reach: 'Reach',
    post_impressions: 'Post Impressions',
    post_reactions: 'Post Reactions',
    posts_per_day: 'Posts',
    info_icon:
      'The development will be calculated based on the development in the current period compared to the previous period.',
    no_data_available:
      'Unfortunately, no detail information (followers, likes, connections) are being provided by the platform.',
    sync_in_progress: 'Data is being syncronised.',
    data_cant_be_loaded: 'Data cannot be loaded. Please refresh the page.',
    saved_posts_per_day: 'Saved posts in Lomavis',
    saved_posts_per_day_info_text: 'Number of posts saved in Lomavis',
    value_time_range_before: 'Value in previous period',
    value_before: 'Comparison value'
  },
  overviewCard: {
    info_icon:
      'The development will be calculated based on the first and last value of the selected period.',
    posts_per_day: 'Posts'
  },
  insights: 'Performance',
  profile_analytics: {
    linkedinPersonalAccount: {
      info_title: 'Info',
      info_text:
        'Unfortunately, no historical data is provided by LinkedIn for personal accounts. Insights can only be retrieved for LinkedIN company pages and posts published with Lomavis. You can still create, plan, edit and publish posts for your personal account with Lomavis.'
    }
  },
  no_data_for_google_only_post:
    'No post analytics data is available for Google My Business posts, because the platform does not provide the data.',
  short_analytics_info:
    'The analysis data are sometimes not made available by the platforms. The missing information is marked by "-". No data is provided by the platform for posts published on Google My Business.',
  post_is_imported: 'Imported Post',
  post_is_created_via_multipost: 'Created via MultiPost',
  post_is_story: 'Post has been published as story',
  connected_platforms: 'Connected Platforms',
  publishing_date: 'Published',

  accounts: {
    team: {
      table: {
        profile_groups: 'Profile Groups',
        cloud: 'Cloud',
        cockpit: 'Cockpit',
        of: 'of',
        members_per_page: 'Members per page',
        name: 'Name',
        user: 'User',
        role: 'Role',
        all: 'All',
        edit: 'Edit',
        delete: 'Delete',
        close: 'Close',
        confirm: 'Confirm',
        delete_user_confirmation: 'Are you sure you want to delete this user?',
        action: 'Action'
      }
    },
    invites: {
      table: {
        profile_groups: 'Profile Groups',
        clouds: 'Clouds',
        cockpits: 'Cockpits',
        of: 'of',
        cloud_role: 'Cloud Role',
        cockpit_role: 'Cockpit Role',

        members_per_page: 'Members per page',
        name: 'Name',
        user: 'User',
        role: 'Role',
        all: 'All',
        edit: 'Edit',
        delete: 'Delete',
        close: 'Close',
        confirm: 'Confirm',
        action: 'Action',
        selected: 'Selected',
        created_by: 'Created by',
        delete_invite_confirmation:
          'Are you sure you want to delete this invite?',
        locations_per_page: 'Locations per page',
        clouds_per_page: 'Clouds per page'
      }
    }
  },
  pages: {
    accounts: {
      team: {
        invites: 'Invites',
        add_member: 'Add Member',
        upgrade_subscription: 'Upgrade Subscription',
        team_count: 'Team Count'
      }
    }
  },
  cloud: 'Cloud',
  cockpit: 'Cockpit',
  config: {
    roles: {
      admin: 'Admin',
      publisher: 'Publisher',
      creator: 'Creator',
      guest: 'Guest',
      calendar: 'Calendar',
      analytics: 'Analytics',
      media_upload: 'Media Upload',
      approval: 'Approval'
    }
  },
  invite: {
    select_language: 'Select Language',
    createForm: {
      invalid_email_format: 'Enter a valid email address.',
      email_max_length_64: 'Email must be less than 64 characters.',
      email: 'Email',
      role: 'Role',
      cloud_role: 'Cloud Role',
      cockpit_role: 'Cockpit Role',
      no_access: 'No Access',
      first_name: 'First Name',
      last_name: 'Last Name',
      permissions: 'Permissions',
      send_invite: 'Send Invite',
      add_user: 'Add User',
      table: {
        created_by: 'Created by'
      }
    },
    permissions: {
      roles_and_premissions: 'Roles and Permissions',
      manage_sbscription: 'Manage Subscription',
      manage_team: 'Manage Team',
      create_drafts: 'Create Drafts',
      edit_drafts: 'Edit Drafts',
      publish_posts: 'Publish Posts',
      access_to_communication_center: 'Access to Communication Center',
      update_social_media_profiles: 'Update Social Media Profiles',
      connect_social_media_accounts: 'Connect Social Media Accounts',
      disconnect_social_media_accounts: 'Disconnect Social Media Accounts'
    }
  },
};

export default en;
