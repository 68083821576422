import {
    ANALYTICS_COLOR_1,
    ANALYTICS_COLOR_2,
    ANALYTICS_COLOR_3,
    ANALYTICS_COLOR_4,
    ANALYTICS_COLOR_5,
    ANALYTICS_COLOR_POSTS
} from '../../../config/constants';
import {displayFunctions} from '../../../utils/chartsUtils';

export const clicks_on_page = {
    addAdditionalPlots: true,
    page_visits: {
        title: 'mixedChart.clicks_on_page',
        hexColor: ANALYTICS_COLOR_1,
        type: 'line',
        display: displayFunctions.sum
    },
    website_button: {
        title: 'mixedChart.website_button',
        hexColor: ANALYTICS_COLOR_2,
        type: 'line',
        display: displayFunctions.sum
    }
};

export const engagement = {
    addAdditionalPlots: true,
    average_comments: {
        title: 'mixedChart.average_comments',
        hexColor: ANALYTICS_COLOR_1,
        type: 'line',
        display: displayFunctions.sum
    },
    average_likes: {
        title: 'mixedChart.average_likes',
        hexColor: ANALYTICS_COLOR_2,
        type: 'line',
        display: displayFunctions.sum
    },
    average_shares: {
        title: 'mixedChart.average_shares',
        hexColor: ANALYTICS_COLOR_3,
        type: 'line',
        display: displayFunctions.sum
    },
    likes_count: {
        title: 'mixedChart.likes_count',
        hexColor: ANALYTICS_COLOR_4,
        type: 'line',
        display: displayFunctions.sum
    }
};

export const follower_details = {
    addAdditionalPlots: true,
    followers: {
        title: 'mixedChart.followers',
        hexColor: ANALYTICS_COLOR_1,
        type: 'line',
        display: displayFunctions.sum
    },
    following: {
        title: 'mixedChart.following',
        hexColor: ANALYTICS_COLOR_2,
        type: 'line',
        display: displayFunctions.lastValue
    },
};

export const growth = {
    addAdditionalPlots: true,
    reactions: {
        title: 'mixedChart.reactions',
        hexColor: ANALYTICS_COLOR_1,
        type: 'line',
        display: displayFunctions.sum
    },
    followers: {
        title: 'mixedChart.followers',
        hexColor: ANALYTICS_COLOR_2,
        type: 'line',
        display: displayFunctions.lastValue
    },
    impressions: {
        title: 'mixedChart.impressions',
        hexColor: ANALYTICS_COLOR_3,
        type: 'line',
        display: displayFunctions.lastValue
    },
    page_visits: {
        title: 'mixedChart.page_visits',
        hexColor: ANALYTICS_COLOR_4,
        type: 'line',
        display: displayFunctions.lastValue
    }
};

export const interactions = {
    addAdditionalPlots: true,
    reactions: {
        title: 'mixedChart.reactions',
        hexColor: ANALYTICS_COLOR_1,
        type: 'line',
        display: displayFunctions.sum
    },
    comments: {
        title: 'mixedChart.comments',
        hexColor: ANALYTICS_COLOR_2,
        type: 'line',
        display: displayFunctions.lastValue
    },
    shares: {
        title: 'mixedChart.shares',
        hexColor: ANALYTICS_COLOR_3,
        type: 'line',
        display: displayFunctions.lastValue
    }
};

export const follower_development = {
    addAdditionalPlots: true,
    gained: {
        title: 'mixedChart.gained',
        hexColor: ANALYTICS_COLOR_1,
        type: 'line',
        display: displayFunctions.sum
    },
};

export const newConfigsMap: any = {
    growth,
    interactions,
    follower_development,
    //follower_development,
    //clicks_on_page,
    //engagement,
    //follower_details,
    posts_per_day: {
        normalizeValues: true,
        title: 'mixedChart.posts_per_day',
        hexColor: ANALYTICS_COLOR_POSTS,
        type: 'bar',
        display: displayFunctions.sum,
        additionalPlot: true
    }
};
