export const allLangs = [
  {
    label: 'English',
    value: 'en-us',
    systemValue: 'en',
    icon: '/static/dashboard/theme/assets/media/flags/226-united-states.svg'
  },
  {
    label: 'Deutsch',
    value: 'de',
    systemValue: 'de',
    icon: '/static/dashboard/theme/assets/media/flags/162-germany.svg'
  }
];

export const defaultLang = allLangs[0]; // English
