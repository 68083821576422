import axios from "axios";
import {FACEBOOK, INSTAGRAM} from "../../../../config/constants";

export const loadAllDataFromApi = async (url: string, loadingCallback: any | null) => {
    return new Promise<any>(async (resolve, reject) => {
        let finalResult = [];
        while (url) {
            try {
                const response = await axios.get(url);
                url = response.data.next;

                finalResult = finalResult.concat(response.data.results);

                loadingCallback && loadingCallback(response.data);

            } catch (error) {
                console.error('Error fetching facebook pages', error);
                reject(error)
            }
        }
        resolve(finalResult);
    })
}

/*
* Load all facebook pages from the backend
* */
export const getFacebookPages = async (
    connectingSocialMediaUserUuid: string, loadingCallback: any | null
) => {
    return new Promise<any>(async (resolve, reject) => {
        let finalResult = [];
        let url = `/api/v1/platforms/facebook/facebook_pages/?connecting_social_media_user_uuid=${connectingSocialMediaUserUuid}`;
        while (url) {
            try {
                const response = await axios.get(url);
                url = response.data.next;

                finalResult = finalResult.concat(response.data.results);

                loadingCallback && loadingCallback(response.data);

            } catch (error) {
                console.error('Error fetching facebook pages', error);
                reject(error)
            }
        }
        resolve(finalResult);
    })
};

/*
*
* */
export const getFacebookGroups = async (
    connectingSocialMediaUserUuid: string, loadingCallback: any | null
) => {
    return new Promise<any>(async (resolve, reject) => {
        let finalResult = [];
        let url = `/api/v1/platforms/facebook/facebook_group/?connecting_social_media_user_uuid=${connectingSocialMediaUserUuid}`;
        while (url) {
            try {
                const response = await axios.get(url);
                url = response.data.next;

                finalResult = finalResult.concat(response.data.results);

                loadingCallback && loadingCallback(response.data);

            } catch (error) {
                console.error('Error fetching facebook pages', error);
                reject(error)
            }
        }
        resolve(finalResult);
    })
};

export const getInstagramAccounts = async (connectingSocialMediaUserUuid: string, loadingCallback: any | null) => {
    return loadAllDataFromApi(`/api/v1/platforms/facebook/instagram_accounts/?connecting_social_media_user_uuid=${connectingSocialMediaUserUuid}`, loadingCallback);
}


export const getLinkedinPersonalAccounts = async (connectingSocialMediaUserUuid: string, loadingCallback: any | null) => {
    return loadAllDataFromApi(`/api/v1/platforms/linkedin/connected/linkedin-personal-accounts/?connecting_social_media_user_uuid=${connectingSocialMediaUserUuid}`, loadingCallback);
}

export const getLinkedinBusinessPages = async (connectingSocialMediaUserUuid: string, loadingCallback: any | null) => {
    return loadAllDataFromApi(`/api/v1/platforms/linkedin/connected/linkedin-business-pages/?connecting_social_media_user_uuid=${connectingSocialMediaUserUuid}`, loadingCallback);
}

export const getTwitterAccounts = async (connectingSocialMediaUserUuid: string, loadingCallback: any | null) => {
    return loadAllDataFromApi(`/api/v1/platforms/twitter/connected/twitter-accounts/?connecting_social_media_user_uuid=${connectingSocialMediaUserUuid}`, loadingCallback);
}
export const getYoutubeChannels = async (connectingSocialMediaUserUuid: string, loadingCallback: any | null) => {
    return loadAllDataFromApi(`/api/v1/platforms/google/connected/youtube-channels/?connecting_social_media_user_uuid=${connectingSocialMediaUserUuid}`, loadingCallback);
}

export const getGMBLocations = async (connectingSocialMediaUserUuid: string, loadingCallback: any | null) => {
    return loadAllDataFromApi(`/api/v1/platforms/google/connected/gmb-locations/?connecting_social_media_user_uuid=${connectingSocialMediaUserUuid}`, loadingCallback);
}

export const getTikTokBusinessPages = async (connectingSocialMediaUserUuid: string, loadingCallback: any | null) => {
    return loadAllDataFromApi(`/api/v1/platforms/tiktok/connected/tiktok-business-pages/?connecting_social_media_user_uuid=${connectingSocialMediaUserUuid}`, loadingCallback);
}
