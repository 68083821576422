import { allLangs, defaultLang } from './config-lang';

import { useTranslate } from '@tolgee/react';

// ----------------------------------------------------------------------

export default function useLocales(): any {
  const { t: translate } = useTranslate();
  const lang = document.getElementsByTagName('html')[0].getAttribute('lang');
  let currentLang = allLangs.find((_lang) => _lang.value === lang);
  if (currentLang == null) currentLang = defaultLang;
  const handleChangeLanguage = (newlang: string): void => {
    console.error('change language called!!');
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate: (text: any, options?: any) => {
      const result = translate(text, options);
      return result;
    },
    currentLang,
    allLangs
  };
}
