import { displayFunctions } from '../../../utils/chartsUtils';

export const data = {
  plots: {
    growth: {
      reactions: {
        trend: 1,
        values: [50, 60, 45, 70, 80, 75, 90],
        displayValue: displayFunctions.sum([50, 60, 45, 70, 80, 75, 90])
      },
      followers: {
        trend: 1,
        values: [150, 200, 220, 240, 270, 290, 320],
        displayValue: displayFunctions.sum([150, 200, 220, 240, 270, 290, 320])
      },
      impressions: {
        trend: 1,
        values: [100, 120, 130, 140, 150, 160, 170],
        displayValue: displayFunctions.sum([100, 120, 130, 140, 150, 160, 170])
      },
      page_visits: {
        trend: 1,
        values: [20, 25, 30, 35, 40, 45, 50],
        displayValue: displayFunctions.sum([20, 25, 30, 35, 40, 45, 50])
      }
    },
    reach_and_impressions: {
      reach: {
        trend: 0,
        values: [80, 100, 120, 90, 110, 100, 120],
        displayValue: displayFunctions.sum([80, 100, 120, 90, 110, 100, 120])
      },
      impressions: {
        trend: 0,
        values: [100, 120, 130, 140, 150, 160, 170],
        displayValue: displayFunctions.sum([100, 120, 130, 140, 150, 160, 170])
      }
    },
    follower_details: {
      followers: {
        trend: 1,
        values: [150, 200, 220, 240, 270, 290, 320],
        displayValue: 320
      },
      fans: {
        trend: 1,
        values: [100, 120, 130, 140, 150, 160, 170],
        displayValue: 170
      }
    },
    follower_development: {
      gained: {
        trend: 1,
        values: [10, 15, 20, 18, 22, 25, 28],
        displayValue: 28
      },
      lost: {
        trend: 1,
        values: [5, 3, 6, 2, 8, 10, 5],
        displayValue: 5
      }
    },
    clicks_on_page: {
      call_to_action: {
        trend: 0,
        values: [5, 7, 6, 8, 7, 10, 9],
        displayValue: displayFunctions.sum([5, 7, 6, 8, 7, 10, 9])
      },
      website_button: {
        trend: 0,
        values: [3, 4, 2, 5, 4, 3, 6],
        displayValue: displayFunctions.sum([3, 4, 2, 5, 4, 3, 6])
      },
      page_visits: {
        trend: 0,
        values: [20, 25, 30, 35, 40, 45, 50],
        displayValue: displayFunctions.sum([20, 25, 30, 35, 40, 45, 50])
      }
    }
  },
  additionalData: {
    posts_per_day: {
      trend: -1,
      values: [0, 0, 1, 0, 0, 2, 1],
      displayValue: 3
    }
  },
  dates: getLastSevenDays()
};

function getLastSevenDays(): any {
  const dates = [];
  for (let i = 1; i < 8; i++) {
    const date = new Date();
    date.setDate(date.getDate() - i);
    dates.push(date.toISOString().split('T')[0] + 'T00:00:00+00:00');
  }
  return dates.reverse();
}
