import {
    COMMENTS,
    IMPRESSIONS,
    REACTIONS,
    SHARES,
    VIDEO_VIEWS
} from '../../config/constants';

const en = {
    insights_not_available_by_platform: 'Analysedaten werden von der Platform nicht zur Verfügung gestellt.',
    growth: 'Entwicklung',
    post_reach: 'Post Reichweite',
    engagement: 'Engagement',
    last_published_post: 'Zuletzt veröffentlicht',
    best_performance_post: 'Beste Performance',
    next_to_publish_post: 'Nächste Veröffentlichung',
    last_edited_post: 'Zuletzt Bearbeitet',
    your_last_posts: 'Letzte Posts',
    post: 'Post',
    type: 'Typ',
    published_on: 'Plattform',
    date: 'Datum',
    search: 'Suche',
    columns: 'Spalten',
    apply: 'Anwenden',
    cancel: 'Abbrechen',
    loading: 'Laden...',
    updating: 'Aktualisieren',
    content_type_is_story_post_ony: 'Stories',
    content_type_is_text_post: 'Text',
    content_type_is_single_image_post_ony: 'Einzelbild',
    content_type_is_multi_image_post_ony: 'Mehrere Bilder',
    content_type_is_video_post_ony: 'Videos',
    content_type_is_reel_post_ony: 'Reels',
    open_all_plots: 'Alle Analytics öffnen',
    facebook: 'Facebook',
    twitter: 'Twitter',
    instagram: 'Instagram',
    google: 'Google',
    linkedin_personal_account: 'Linkedin Persönliches Profil',
    linkedin_business_page: 'Linkedin Seite',
    last_seven_days: 'Letzte sieben Tage',
    this_week: 'Diese Woche',
    last_week: 'Vorherige Woche',
    this_month: 'Dieser Monat',
    last_month: 'Letzter Monat',
    Calendar: 'Kalendar',
    Cancel: 'Abbrechen',
    Apply: 'Anwenden',
    likes: 'Likes',
    followers: 'Follower',
    page_visits: 'Seiten Impressionen',
    posts: 'Posts',
    last_edited_by: 'Last Edited by',
    created_by: 'Created by',
    last_update: 'Last Updated',
    publication_date: 'Publication Date',
    list_view: 'Listenansicht',
    analytics: 'Analytics',
    edit: 'Bearbeiten',
    view: 'Ansehen',
    delete: 'Löschen',
    no_content_available: 'Kein Inhalte verfügbar',
    created: 'Erstellt',
    old_to_new: 'Alt zu neu',
    new_to_old: 'Neu zu alt',
    published: 'Veröffentlicht',
    edited: 'Bearbeitet',
    organic: 'Organisch',
    Fans: 'Follower',
    fans: 'Gefällt mir',
    call_to_action: 'Call to action',
    website_button: 'Website button',
    interactions: 'Interaktionen',
    online_followers: 'Online follower',
    reach: 'Reichweite',
    clicks: 'Klicks',
    organic_summary: 'Zusammenfassung (Organisch)',
    organic_interactions: 'Interaktionen (Organisch)',
    facebook_clicks: 'Klicks',
    followers_development: 'Follower-Entwicklung',
    fan_development: 'Fanentwicklung',
    follower_development: 'Followerentwicklung',
    post_impressions: 'Post Impressionen',
    shared: 'Geteilt',
    overview: 'Übersicht',
    reviewers: 'Reviewers',
    star_rating: 'Bewertung',
    google_search: 'Google Suche',
    google_maps: 'Google Maps',
    total: 'Gesamt',
    website: 'Website',
    phone: 'Telefon',
    directions: 'Navigation',
    tweets: 'Tweets',
    following: 'Following',
    summary: 'Zusammenfassung',
    no_data_available: 'Keine Daten vorhanden',
    clicks_on_page: 'Klicks auf Seite',
    posts_published_in_period: 'Veröffentlichte Posts in diesem Zeitraum',
    community: 'Community',
    tweets_published_in_period: 'Veröffentlichte Tweets im ausgewähltem Zeitraum',
    account: 'Account',
    location: 'Location',
    posts_viewed_in_period: 'Posts in diesem Zeitraum',
    impressions_by_type: 'Impressionen nach Typ',
    videos: 'Videos',
    paid: 'Bezahlt',
    posts_impressions_in_period: 'Impressionen in diesem Zeitraum',
    retweets: 'Retweets',
    profile_view: 'Profilansichten',
    profile_views: 'Profilansichten',
    post_reactions: 'Post Reaktionen',
    profile_followers: 'Profil Follower',
    views: 'Ansichten',
    video_impressions: 'Video Impressionen',
    impressions_by_post_type: 'Impressionen nach Post Typ',
    impressions_by_source: 'Impressionen nach Quelle',
    development_during_the_first_30_days: 'Entwicklung der ersten 30 Tage',
    go_to_post: 'Zum Post',
    rows_per_page: 'Posts pro Seite',
    of: 'von',
    [IMPRESSIONS]: 'Impressionen',
    [REACTIONS]: 'Reaktionen',
    [SHARES]: 'Shares',
    [COMMENTS]: 'Kommentare',
    [VIDEO_VIEWS]: 'Videoansichten',
    metric_not_available_for_platform:
        'Diese Metrik ist für diese Plattform nicht verfügbar',
    its_not_possible_to_change_date_range_on_demo_location:
        'Demo: Es ist nicht möglich den Zeitraum zu ändern',
    platform_analytics: {
        google_info: 'Google Daten sind nur bis vorgestern verfügbar',
    },
    mixedChart: {
        mixedChart: {
            posts_per_day: 'Posts',
            posts_per_day_plot: 'Posts pro Tag',
        },
        there_is_some_missing_data: 'Einige historische Daten konnten auf Grund von Beschränkungen des Sozialen Netzwerks nicht geladen werden.',
        data_import_is_currently_in_progress: 'Die Daten werden gerade noch für diesen Zeitraum importiert und sind in Kürze für den ausgewählten Zeitraum verfügbar.',
        google_info: 'Google Daten sind nur bis vorgestern verfügbar',
        impressions_desktop_search: 'Google Suche',
        impressions_desktop_maps: 'Google Maps',
        impressions_mobile_search: 'Google Suche',
        impressions_mobile_maps: 'Google Maps',
        overview: 'Übersicht',
        post_reactions: "Post Reaktionen",
        post_impressions: "Post Impressionen",
        post_reach: "Post Reichweite",
        Growth: 'Übersicht',
        growth: 'Übersicht',
        impressions: 'Impressionen',
        reach: 'Reichweite',
        reactions: 'Reaktionen',
        instagram: 'Instagram',
        linkedin_business_page: 'LinkedIn Business Page',
        google: 'Google',
        facebook: 'Facebook',
        twitter: 'Twitter',
        linkedin_personal_account: 'LinkedIn Personal Account',
        posts_per_day: 'Posts',
        no_data_is_available: 'Keine Daten für ausgewählten Zeitraum verfügbar.',
        reach_and_impressions: 'Reichweite und Impressionen',
        page_visits: 'Seitenbesuche',
        follower_details: 'Follower Details',
        fans: 'Gefällt mir',
        follower_development: 'Follower Entwicklung',
        gained: 'Gewonnen',
        lost: 'Verloren',
        clicks_on_page: 'Klicks auf Seite',
        call_to_action: 'CTA',
        website_button: 'Website Button',
        total_interactions: 'Gesamt Interaktionen',
        profile_followers: 'Profil Follower',
        profile_analytics: 'Profil Analytics',
        profile_links_taps: 'Profil Links Klicks',
        website_clicks: 'Website Klicks',
        profile_views: 'Profilansichten',
        business_profile_interactions: 'Business Profil Interaktionen',
        impressions_desktop: 'Impressionen Desktop',
        maps: 'Maps',
        search: 'Suche',
        impressions_mobile: 'Impressionen Mobil',
        calls: 'Anrufe',
        messages: 'Nachrichten',
        bookings: 'Buchungen',
        directions: 'Navigationsanfragen',
        paid_followers: 'Bezahlte Follower',
        organic_followers: 'Organische Follower',
        new_followers: 'Neue Follower',
        new_paid_followers: 'Neue bezahlte Follower',
        new_organic_followers: 'Neue organische Follower',
        unique_page_views: 'Seitenreichweite',
        followers: 'Follower',
        likes: 'Likes',
        at_least_one_metric_required: 'Mindestens eine Metrik ist erforderlich',
        profile_engagement: 'Engagement',
        page_views: "Seitenansichten",
        posts_per_day_plot: 'Posts pro Tag',
        subscribers_gained: 'Gewonnen',
        subscribers: 'Subscribers',
        subscribers_lost: 'Verloren',
        interactions: 'Interatkionen',
        shares: 'Geteilt',
        comments: 'Kommentare',
        dislikes: 'Dislikes',
        error_while_loading_data: 'Es konnten keine Daten geladen werden',
    },
    last_30_days: 'Letze 30 Tage',
    last_60_days: 'Letze 60 Tage',
    custom_range: 'Benutzerdefiniert',
    last_3_months: 'Letzte 3 Monate',
    last_6_months: 'Letzte 6 Monate',
    last_12_months: 'Letzte 12 Monate',
    no_posts_have_been_published_with_lomavis:
        'Es wurden keine Posts mit veröffentlicht',
    instagram_profile_followers_info: 'Historische Daten sind nur für Instagram Business Profile mit über 100 Followern verfügbar',
    range_should_at_least_be_5_days_long: 'Wähle einen Zeitraum von mindestens 5 Tagen',
    data_not_available_for_facebook_group_posts:
        'Diese Metric steht für Facebook-Gruppen-Posts nicht zur Verfügung',
    twitter_profile_analytics: {
        twitter_analytics_info: 'Es ist nicht möglich, historische Daten für Twitter zu erhalten. Solange ein Konto verbunden ist, werden täglich Analyse-Daten abgerufen, um Ihr Analyse-Dashboard zu erstellen.',
    },
    topPosts: {
        no_next_to_publish_post: 'Kein Post geplant',
        no_best_performance_post: 'Kein Post verfügbar',
        no_last_published_post: 'Kein Post verfügbar',
        no_last_edited_post: 'Kein Post verfügbar',
        timerange_info: 'Es werden Posts berücksichtigt, die in den letzten 28 Tagen veröffentlicht wurden. Die Anzahl der Impressionen wird genutzt um die besten Posts zu ermitteln.',
        post_is_shared_without_comment: 'Beitrag wurde ohne Kommentar geteilt',
    },
    post_types: {
        single_image_post: 'Post mit einem Bild',
        multi_image_post: 'Multi-Image Post',
        video_post: 'Video Post',
        reel_post: 'Reel',
        story_post: 'Story',
        text_post: 'Text Post',
        imported_post: 'Importierter Post',
        post_is_shareable: 'Geteilter post'
    },
    analyticsCards: {
        last_28_days: 'Letzte 28 Tage',
        reach_info_text: 'Anzahl der Personen, die einen Ihrer Posts in dem Zeitraum mindestens einmal gesehen haben. Reichweite unterscheidet sich von Impressionen dahingehend, dass mehrere Aufrufe durch eine Person nicht mehrfach gezählt werden.',
        impressions_info_text: 'Anzahl, wie oft Ihre Posts im Zeitraum gesehen wurden. Impressionen unterscheiden sich von Reichweite dahingehend, dass jedesmal eine Impression gezählt wird, wenn der Beitrag gesehen wird. Dies gilt auch auch wenn dieselbe Person deinen Beitrag mehrfach sieht.',
        reactions_info_text: 'Anzahl der Reaktionen auf deine Posts im Zeitraum. Dazu zählen alle Reaktionen wie Likes, Liebe, Applaus, etc.',
        posts_per_day_info_text: 'Anzahl der Posts, die im Zeitraum veröffentlicht wurden sind. Es werden normale Posts, Video-Posts und Reels berücksichtigt. Stories können nicht berrücksichtigt werden, da diese nach maximal 24 Stunden nicht mehr verfügbar sind.',
        saved_posts_per_day_info_text: 'Anzahl der Posts, die in Lomavis im Zeitraum gespeichert wurden.',
    },
    overallCard: {
        Reach: 'Reichweite',
        post_impressions: 'Impressionen',
        post_reactions: 'Reaktionen',
        posts_per_day: 'Posts',
        info_icon: 'Die Entwicklung wird basierend auf der Entwicklung des ausgewählten Zeitraums und des vorhergehenden Zeitraums berechnet.',
        no_data_available: 'Leider werden keine Details (Follower, Gefällt-mir-Angaben, Verbindungen) von der Platform bereitgestellt.',
        sync_in_progress: 'Daten werden synchronisiert.',
        value_time_range_before: 'Wert im vorhergehenden Zeitraum',
        value_before: 'Vergleichswert',
        data_cant_be_loaded: 'Daten konnten nicht geladen werden. Bitte laden Sie die Seite neu.',
        saved_posts_per_day: 'Beiträge in Lomavis',
        saved_posts_per_day_info_text: 'Anzahl der Posts, die in Lomavis im Zeitraum gespeichert wurden.'

    },
    overviewCard: {
        info_icon: 'Die Entwicklung wird basierend auf dem Unterschied zwischen dem ersten und dem letzten Tag des ausgewählten Zeitraums berechnet.',
        posts_per_day: 'Posts'
    },
    insights: "Performance",
    profile_analytics: {
        linkedinPersonalAccount: {
            info_title: 'title',
            info_text: 'Leider werden von LinkedIn keine historischen Daten für persönliche Konten bereitgestellt. Analytics können nur für LinkedIn-Unternehmensseiten abgerufen werden. Sie können weiterhin Posts für Ihren persönlichen Account bei Lomavis erstellen, planen, bearbeiten und veröffentlichen. Für Posts, die mit Lomavis veröffentlicht sind, werden die Analysedaten ab dem Zeitpunkt der Veröffentlichung gesammelt.',
        }
    },
    no_data_for_google_only_post: 'Für Google My Business Posts werden keine Daten von der Platform bereitgestellt.',
    short_analytics_info: 'Die Analysedaten werden von den Plattformen teilweise nicht zur Verfügung gestellt. Die fehlenden Informationen werden durch "-" markiert. Für Posts veröffentlicht auf Google My Business werden keine Daten von der Platform bereitgestellt.',
    post_is_imported: 'Importierter Post',
    post_is_created_via_multipost: 'Erstellt via Multipost',
    post_is_story: 'Post wurde als Story veröffentlicht',
    connected_platforms: 'Verbundene Plattformen',
    publishing_date: 'Veröffentlicht',
    platforms: "Plattformen",
    short_analytics_table: {
        analytics_data_is_currently_synchronizing:
            'Analytics werden gerade syncronisiert und stehen bald zur Verfügung.',
    },
};

export default en;


/*
* growth: 'Entwicklung',
  impressions: 'Impressionen',
  engagement: 'Engagement',
  video_views: 'Videoansichten',
  last_published_post: 'Zuletzt veröffentlicht',
  best_performance_post: 'Beste Performance',
  next_to_publish_post: 'Nächste Veröffentlichung',
  last_edited_post: 'Zuletzt Bearbeitet',
  your_last_posts: 'Letzte Beiträge',
  post: 'Post',
  type: 'Typ',
  published_on: 'Plattform',
  date: 'Datum',
  reactions: 'Reaktionen',
  comments: 'Kommentare',
  shares: 'Shares',
  search: 'Suche',
  columns: 'Spalten',
  apply: 'Anwenden',
  cancel: 'Abbrechen',
  loading: 'Laden...',
  updating: 'Aktualisieren',
  content_type_is_story_post_ony: 'Stories',
  content_type_is_text_post: 'Text',
  content_type_is_single_image_post_ony: 'Einzelbild',
  content_type_is_multi_image_post_ony: 'Mehrere Bilder',
  content_type_is_video_post_ony: 'Videos',
  content_type_is_reel_post_ony: 'Reels',
  open_all_plots: 'Alle Analytics öffnen',
  facebook: 'Facebook',
  twitter: 'Twitter',
  instagram: 'Instagram',
  google: 'Google',
  linkedin_personal_account: 'Linkedin Persönliches Profil',
  linkedin_business_page: 'Linkedin Seite',
  last_seven_days: 'Letzte sieben Tage',
  this_week: 'Diese Woche',
  last_week: 'Vorherige Woche',
  this_month: 'Dieser Monat',
  last_month: 'Letzter Monat',
  Calendar: 'Kalendar',
  Cancel: 'Abbrechen',
  Apply: 'Anwenden',
  likes: 'Likes',
  followers: 'Follower',
  page_visits: 'Seiten Impressionen',
  posts: 'Posts',
  last_edited_by: 'Last Edited by',
  created_by: 'Created by',
  last_update: 'Last Updated',
  publication_date: 'Publication Date',
  list_view: 'Listenansicht',
  analytics: 'Analytics',
  edit: 'Bearbeiten',
  view: 'Ansehen',
  delete: 'Löschen',
  no_content_available: 'Kein Inhalte verfügbar',
  created: 'Erstellt',
  old_to_new: 'Alt zu neu',
  new_to_old: 'Neu zu alt',
  published: 'Veröffentlicht',
  edited: 'Bearbeitet',
  organic: 'Organisch',
  Fans: 'Followers',
  fans: 'fans',
  call_to_action: 'Call to action',
  website_button: 'Website button',
  interactions: 'Interaktionen',
  online_followers: 'Online followers',
  reach: 'Reichweite',
  clicks: 'Klicks',
  organic_summary: 'Zusammenfassung (Organisch)',
  organic_interactions: 'Interaktionen (Organisch)',
  facebook_clicks: 'Klicks',
  followers_development: 'Follower-Entwicklung',
  fan_development: 'Fanentwicklung',
  follower_development: 'Followerentwicklung',
  post_impressions: 'Post Impressionen',
  shared: 'Geteilt',
  overview: 'Übersicht',
  reviewers: 'Reviewers',
  star_rating: 'Bewertung',
  google_search: 'Google Suche',
  google_maps: 'Google Maps',
  total: 'Gesamt',
  website: 'Website',
  phone: 'Telefon',
  directions: 'Navigation',
  tweets: 'Tweets',
  following: 'Following',
  summary: 'Zusammenfassung',
  no_data_available: 'Keine Daten vorhanden',
  clicks_on_page: 'Klicks auf Seite',
  posts_published_in_period: 'Veröffentlichte Posts in diesem Zeitraum',
  community: 'Community',
  tweets_published_in_period: 'Veröffentlichte Tweets im ausgewähltem Zeitraum',
  account: 'Account',
  location: 'Location',
  posts_viewed_in_period: 'Posts in diesem Zeitraum',
  impressions_by_type: 'Impressionen nach Typ',
  videos: 'Videos',
  paid: 'Bezahlt',
  posts_impressions_in_period: 'Impressionen in diesem Zeitraum',
  retweets: 'Retweets',
  profile_view: 'Profilansichten',
  profile_views: 'Profilansichten',
  post_reactions: 'Post Reaktionen',
  profile_followers: 'Profil Follower',
  views: 'Ansichten',
  video_impressions: 'Video Impressionen',
  impressions_by_post_type: 'Impressionen nach Post Typ',
  impressions_by_source: 'Impressionen nach Quelle',
  development_during_the_first_30_days: 'Entwicklung der ersten 30 Tage',
  go_to_post: 'Zum Post',
  rows_per_page: 'Beiträge pro Seite',
  of: 'von',
  [IMPRESSIONS]: 'Impressionen',
  [REACTIONS]: 'Reaktionen',
  [SHARES]: 'Shares',
  [COMMENTS]: 'Kommentare',
  [VIDEO_VIEWS]: 'Videoansichten'
*
*
* */
