import React, {useEffect} from 'react';
import {useTranslate} from "@tolgee/react";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import axios from "axios";
import {
    FACEBOOK,
    FACEBOOK_GROUP,
    GOOGLE,
    INSTAGRAM,
    PLATFORMS_COLORS, SELECTED_PLATFORM_COLOR_CODE,
    TIKTOK_PERSONAL_ACCOUNT,
    TWITTER
} from "../../../../../config/constants";
import {
    getFacebookGroups,
    getFacebookPages, getGMBLocations,
    getInstagramAccounts, getLinkedinBusinessPages,
    getLinkedinPersonalAccounts, getTikTokBusinessPages, getTwitterAccounts, getYoutubeChannels
} from "../../../facebook_/utils/api";
import {LINKEDIN, META, URL_PARAM_PLATFORM} from "../../../constants";
import SocialMediaProfileTable from "./table";
import {Spinner} from "react-bootstrap";
import {PATH_PAGE} from "../../../../../routes/paths";

const ConnectedProfilesByConnectedSocialMediaAccountsListView = () => {
    const {t} = useTranslate();
    const [connectedSocialMediaAccounts, setConnectedSocialMediaAccounts] = React.useState(null);
    const [connectedSocialMediaAccountsLoaded, setConnectedSocialMediaAccountsLoaded] = React.useState(false);


    const queryParams = new URLSearchParams(location.search);
    const platform = queryParams.get(URL_PARAM_PLATFORM);
    const connectingSocialMediaUserUuid = queryParams.get('connecting_social_media_user_uuid');

    const platformToFetchingFuncMapping = {
        [META]: [getFacebookPages, getInstagramAccounts],
        [LINKEDIN]: [getLinkedinPersonalAccounts, getLinkedinBusinessPages],
        [TWITTER]: [getTwitterAccounts],
        [GOOGLE]: [getYoutubeChannels, getGMBLocations],
        [TIKTOK_PERSONAL_ACCOUNT]: [getTikTokBusinessPages]
    }

    const loadConnectedSocialMediaAccounts = async () => {
        if (connectedSocialMediaAccounts) {
            return;
        }

        let finalResult = [];
        for (const func of platformToFetchingFuncMapping[platform]) {
            let response = await func(connectingSocialMediaUserUuid);
            response.sort((a, b) => a.profile_name > b.profile_name ? 1 : -1)
            finalResult = finalResult.concat(response);
        }

        setConnectedSocialMediaAccounts(finalResult);
        setConnectedSocialMediaAccountsLoaded(true);
    };

    const columns = [
        {
            name: "",
            selector: row => (
                <img className={"img img-thumbnail"} src={row.profile_picture_url} style={{width: 50, height: 50}}/>
            )
        },
        {
            name: t("verifyFacebookAccessToken.platform"),
            selector: row => (
                <div className={'badge'}
                     style={{backgroundColor: SELECTED_PLATFORM_COLOR_CODE[row.platform_name]}}>{row.platform_display_name}</div>
            )
        },
        {
            name: t("verifyFacebookAccessToken.name"),
            selector: row => (
                <div>{row.profile_name}</div>
            )
        },
        {
            name: t("verifyFacebookAccessToken.deletedOnPlatform"),
            cell: row => (
                row.published_resource_deleted_on_platform ?
                    <>
                        <OverlayTrigger
                            overlay={<Tooltip id={row.uuid}>{t("verifyFacebookAccessToken.deletedTooltip")}</Tooltip>}>
                            <div className="badge bg-danger">{t("warning.deletedOnPlatform")}</div>
                        </OverlayTrigger>
                    </>
                    :
                    <></>
            )
        }
    ];

    const init = () => {
        loadConnectedSocialMediaAccounts();
    }

    useEffect(() => {
        init();
    })

    return (
        <div className={"container"}>


            <div className={"container"}>
                <div className={"mb-5"}>
                    <a className={"btn btn-secondary btn-sm"}
                       href={PATH_PAGE.platforms_connected_social_media_accounts}>{t("connectedSocialMediaAccounts.back")}</a>
                </div>
                {(<div className="card card-flush h-md-100">
                    <div className="card-header pt-7">
                        <h3 className="card-title align-items-start flex-column">
                            <span
                                className="card-label fw-bold text-gray-800">{t('connectedSocialMediaAccounts.connectedSocialMediaAccounts')}</span>
                        </h3>
                    </div>

                    <div className="card-body pt-6">
                        {connectedSocialMediaAccountsLoaded ? (<SocialMediaProfileTable
                            profiles={connectedSocialMediaAccounts}
                            columns={columns}
                        />) : (
                            <div className="d-flex justify-content-center align-items-center">
                                <Spinner animation="border" variant="primary"/>
                            </div>

                        )}

                    </div>
                </div>)}
            </div>


        </div>
    );
};

export default ConnectedProfilesByConnectedSocialMediaAccountsListView;
